import React from 'react';
import Emoji from '../../Content/Emoji';
import MarkdownPage from '../markdownPage';
import { getLanguage } from '../../Language/Language';
import { getString } from '../../Language/LanguageStrings'
import educationData from './education.json'
import CoursesTable from './coursesTable';

const SoftwareMain = () => {
    const language = getLanguage();

    return (
        <article className="main-page">
            <h1 id="list-intro" className="text-primary">{getString('software')} <Emoji symbol="🖥️" /></h1>
            <MarkdownPage md={'Software/' + language + '/main.md'} />
            <hr />
            <h2>{getString('code_pages')}</h2>
            <p>{getString('code_pages_intro')}</p>
            <ul>
            <li><a href={'/' + language + '/software/elm/'}>Elm</a></li>
            <li><a href={'/' + language + '/software/haskell/'}>Haskell</a></li>
            </ul>
            <hr />

            <h2>{getString('software_projects')}</h2>
            <div style={{display: "inline-block"}}>
                <ul>
                    <li><a href="./project/kanji-application">{getString('kanji_application')}</a></li>
                    <li><a href="./project/dxp-development">{getString('dxp_development')}</a></li>
                    <li><a href="./project/config-automation">{getString('config_automation')}</a></li>
                    <li><a href="./project/embedded-game">{getString('embedded_game')}</a></li>
                    <li><a href="./project/metroid-horizontal-shooter">{getString('metroid_horizontal_shooter')}</a></li>
                    <li><a href="./project/raspberry-pi-jukebox">{getString('raspberry_pi_jukebox')}</a></li>
                    <li><a href="./project/teslamaze">{getString('teslamaze')}</a></li>
                </ul>
            </div>
            <div style={{display: "inline-block"}}>
                <ul>
                    <li><a href="./project/wifi-prototype">{getString('wifi_prototype')}</a></li>
                    <li><a href="./project/embedded-internet-radio">{getString('embedded_internet_radio')}</a></li>
                    <li><a href="./project/windows10-cookbook">{getString('windows10_cookbook')}</a></li>
                    <li><a href="./project/ios-airports">{getString('ios_airports')}</a></li>
                    <li><a href="./project/festival-simulator">{getString('festival_simulator')}</a></li>
                    <li><a href="./project/boebot">{getString('boebot')}</a></li>
                    <li><a href="./project/attachment-module">{getString('attachment_module')}</a></li>
                </ul>
            </div>
            <hr />
            <h2>{getString('education')}</h2>
            {educationData.education.map((edu, index) => (
                <div key={edu.name + index}>
                    <h4>{edu.name}</h4>
                    <h6>{edu.diploma}</h6>
                    <p>{edu.school} - {edu.location}</p>
                    <p>{edu.start} - {edu.end}</p>
                </div>
            ))}
            <hr />
            <h2>{getString('certification') + ' & ' + getString('courses')}</h2>
            <CoursesTable />
        </article>
    )
}

export default SoftwareMain;