import { getLanguage } from "./Language";

const languageStrings = {
    en: {
        // Common
        and: 'and',
        copy: 'copy',
        date: 'date',
        hour: 'hour(s)',
        on: 'on',
        name: 'name',
        // Titles
        japan: 'Japan',
        japanese: 'Japanese',
        radio_amateur: 'Radio amateur',
        software: 'Software',
        // Japan
        download_map_at_github: 'Get this map at Github',
        // Education
        certification: 'Certification',
        completed: 'Completed',
        course: 'Cursus',
        courses: 'Courses',
        duration: 'Duration',
        education: 'Education',
        institution: 'Institution',
        instructor: 'Instructor',
        issued: 'issued',
        skills: 'Skills',
        // Navigation
        portal: 'Portal',
        language: 'Language',
        // Radio
        antenna: 'antenna',
        bands: 'bands',
        equipment: 'Equipment',
        components: 'components',
        fielddays: 'Field days',
        nvisAntenna: 'NVIS Antenna',
        totalUniqueQSO: 'total unique QSO',
        // Scale Model
        plamo: 'Plamo',
        scalemodel: 'Scale model',
        // Software
        code_pages: 'Code sections',
        code_pages_intro: 'Some notes and examples of code.',
        software_projects: 'Projects',
        // Projects
        attachment_module: 'Attachment module',
        boebot: 'Board of Education robot',
        config_automation: 'Configuration automation',
        dxp_development: 'DXP development',
        embedded_internet_radio: 'Embedded internet radio',
        embedded_game: 'Embedded game',
        ios_airports: 'iOS airport',
        festival_simulator: 'Festival simulator',
        kanji_application: 'Japanese memory mobile application',
        metroid_horizontal_shooter: 'Metroid horizontal shooter game',
        raspberry_pi_jukebox: 'Raspberry Pi Jukebox',
        teslamaze: 'TeslaMaze',
        wifi_prototype: 'Wi-Fi prototype board',
        windows10_cookbook: 'Windows 10 cookbook',
        // Footer
        footer_contentparsed: 'Content is parsed by',
        footer_madewith: 'This site was made with',
    },
    nl: {
        // Common
        and: 'en',
        copy: 'kopieer',
        date: 'datum',
        hour: 'uur',
        on: 'op',
        name: 'naam',
        // Titles
        japan: 'Japan',
        japanese: 'Japans',
        radio_amateur: 'Radiozendamateur',
        software: 'Software',
        // Japan
        download_map_at_github: 'Download deze kaart op Github',
        // Education
        certification: 'Certificering',
        completed: 'Voltooid',
        course: 'Cursus',
        courses: 'Cursussen',
        duration: 'Duur',
        education: 'Opleiding',
        institution: 'Institutie',
        instructor: 'Instructeur',
        issued: 'uitgegeven',
        skills: 'Vaardigheden',
        // Navigation
        portal: 'Portaal',
        language: 'Taal',
        // Radio
        antenna: 'antenne',
        bands: 'banden',
        equipment: 'Apparatuur',
        components: 'componenten',
        fielddays: 'Velddagen',
        nvisAntenna: 'NVIS antenne',
        totalUniqueQSO: 'totaal unieke QSO',
        // Scale Model
        plamo: 'Plamo',
        scalemodel: 'Schaalmodel',
        // Software
        code_pages: 'Code secties',
        code_pages_intro: 'Notities en voorbeelden van code.',
        software_projects: 'Projecten',
        // Projects
        attachment_module: 'Attachment module',
        boebot: 'Board of Education robot',
        config_automation: 'Configuratie automatisering',
        dxp_development: 'DXP development',
        embedded_internet_radio: 'Embedded internet radio',
        embedded_game: 'Embedded spel',
        ios_airports: 'iOS luchthavens',
        festival_simulator: 'Festival simulator',
        kanji_application: 'Japanse geheugensteun mobiele applicatie',
        metroid_horizontal_shooter: 'Metroid horizontale shooter spel',
        raspberry_pi_jukebox: 'Raspberry Pi Jukebox',
        teslamaze: 'TeslaMaze',
        wifi_prototype: 'Wi-Fi prototype board',
        windows10_cookbook: 'Windows 10 kookboek',

        // Footer
        footer_contentparsed: 'Inhoud is verwerkt door',
        footer_madewith: 'Deze website is gemaakt met',
    },
};

export function getString(key) {
    const language = getLanguage();
    return languageStrings[language][key] || `{${key}}`;
}