import React from 'react';

import educationData from './education.json';
import { useTheme } from '../../ThemeContext';
import { getString } from '../../Language/LanguageStrings';

const CoursesTable = () => {
    const { theme } = useTheme();

    return (
        <table className={'table table-striped table-hover table-' + theme}>
            <thead>
                <tr>
                    <th>{getString('completed')}</th>
                    <th>{getString('duration')}</th>
                    <th>{getString('course')}</th>
                    <th>{getString('skills')}</th>
                    <th>{getString('instructor')}</th>
                    <th>{getString('institution')}</th>
                </tr>
            </thead>
            <tbody>
                {educationData.courses.map((course, index) => (
                    <tr key={index}>
                        <td>{course.achieveDate}</td>
                        <td>{course.duration + ' ' + getString('hour')}</td>
                        <td>{course.name} {course.sub}</td>
                        <td>{course.skills}</td>
                        <td>{course.instructor}</td>
                        <td>{course.institution}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default CoursesTable;
