import React from 'react';
import Breadcrumbs from '../../../UI/Breadcrumbs'
import MarkdownPage from '../../markdownPage'

const HaskellPage = ({ mdPath }) => {
    return (
        <article className='main-page'>
            <Breadcrumbs separator=' > ' path="software/haskell">
                {['software', 'haskell']}
            </Breadcrumbs>
            <MarkdownPage md={mdPath} />
        </article>
    )
}

export default HaskellPage;